import querystring from "querystring";
import client from "./client";

export const isBrowser = () => typeof window !== "undefined";

export const getUser = () => (isBrowser() && window.localStorage.getItem("accountId") ? JSON.parse(window.localStorage.getItem("accountId")) : {});

const descriptor = {
  client_id: "web",
  client_secret: "388D45FA-B36B-4988-BA59-B187D329C207",
  url: "/connect/token",
};

// const worker = new Worker(workerScript);

function auth(username, password) {
  return client
    .post(
      descriptor.url,
      querystring.stringify({
        grant_type: "password",
        username: username,
        password: password,
        client_id: descriptor.client_id,
        client_secret: descriptor.client_secret,
        scope: "offline_access",
      })
    )
    .then((response) => {
      if (response.data) {
        if (isBrowser()) {
          window.localStorage.setItem("access_token", response.data.access_token);
          window.localStorage.setItem("refresh_token", response.data.refresh_token);
          window.localStorage.setItem("firstName", response.data.firstName);
          window.localStorage.setItem("lastName", response.data.lastName);
          window.localStorage.setItem("accountId", response.data.accountId);
          window.localStorage.setItem("accountPhone", response.data.phone);
          window.localStorage.setItem("accountEmail", response.data.email);
          window.localStorage.setItem("roles", response.data.roles);
        }
      }
      return {
        statusText: response.statusText,
        status: response.status,
      };
    })
    .catch((error) => Promise.reject(error));
}

function refreshToken() {
  // temp to see if this fixes error
  if (localStorage.getItem("refresh_token")) {
    return client
      .post(
        descriptor.url,
        querystring.stringify({
          grant_type: "refresh_token",
          refresh_token: localStorage.getItem("refresh_token"),
          client_id: descriptor.client_id,
          client_secret: descriptor.client_secret,
          scope: "offline_access",
        })
      )
      .then((response) => {
        if (response.data) {
          window.localStorage.setItem("access_token", response.data.access_token);
          window.localStorage.setItem("refresh_token", response.data.refresh_token);
        }
      })
      .catch((error) => {
        localStorage.clear();
        if (typeof window !== "undefined") window.location.reload();
        Promise.reject(error);
      });
  } else {
    localStorage.clear();
    if (typeof window !== "undefined") window.location.reload();
  }
}

export const isLoggedIn = () => {
  return !!getUser();
};

export const isMember = () => {
  if (isBrowser()) {
    const roles = localStorage.getItem("roles");
    return roles && roles.includes("Member");
  }
};

export const isAdmin = () => {
  if (isBrowser()) {
    const roles = localStorage.getItem("roles");
    return roles && roles.includes("Admin");
  }
};

export const isManager = () => {
  if (isBrowser()) {
    const roles = localStorage.getItem("roles");
    return roles && roles.includes("Manager");
  }
};

export const isOwner = () => {
  if (isBrowser()){
    const roles = localStorage.getItem("roles");
    return roles && roles.includes("Owner");
  }
}
const auths = { auth, refreshToken };

export default auths;
